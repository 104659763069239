<template>
    <div v-if="!settings.maintenance_mode">
        <!-- <div
            v-if="false"
            class="bg-white rounded-4 p-3 m-3 shadow-lg"
            style="position: absolute; z-index: 9999999; left: 0; right: 0"
        >
            <div
                class="d-flex flex-row justify-content-between align-items-center"
            >
                <div class="d-flex flex-column">
                    Captain Something
                    <span class="text-muted">Said something here...</span>
                </div>
                <div class="px-3 bg">
                    <i class="fa fs-4 fa-xmark text-muted"></i>
                </div>
            </div>
        </div> -->
        <DarkHeader />
        <UpdateAppModal @confirm-update="refreshApp" id="update-app-modal" />
        <button
            data-bs-toggle="modal"
            data-bs-target="#update-app-modal"
            ref="updateAppModalTrigger"
            class="d-none"
        ></button>
        <router-view></router-view>
        <InstallAppModal ref="installAppModal" id="installAppModal" />
        <button
            class="d-none"
            data-bs-toggle="modal"
            data-bs-target="#installAppModal"
            ref="installAppModalTrigger"
        ></button>
        <BottomBar v-if="user.id"></BottomBar>
    </div>
    <div v-else>
        <AppUnderMaintenance />
    </div>
</template>

<script setup>
import DarkHeader from '@/components/DarkHeader.vue';
import BottomBar from '@/components/BottomBar.vue';
import InstallAppModal from '@/components/InstallAppModal.vue';
import UpdateAppModal from '@/components/UpdateAppModal';
import AppUnderMaintenance from '@/views/AppUnderMaintenance';
import { useUserStore } from '@/stores/user-store';
import { storeToRefs } from 'pinia';
import { ref, onMounted, inject } from 'vue';
import { emitter } from '@/mitt';
import { useRemoteSettingsStore } from '@/stores/remote-settings-store';
import Loading from '@/assets/loading-icon.gif'; // Import this now so that the user doesn't have to load the spinner after when it is needed
import Pusher from 'pusher-js';
import { useMessageStore } from './stores/messages-store';
import axios from 'axios';

const GLOBAL_VAR_IS_PRODUCTION = inject('GLOBAL_VAR_IS_PRODUCTION');

const remoteSettingsStore = useRemoteSettingsStore();
remoteSettingsStore.fetchSettings();
const { settings } = storeToRefs(remoteSettingsStore);

const updateAppModalTrigger = ref(null);
const installAppModalTrigger = ref(null);

// Preload loading icon for later use
const loadingImage = new Image();
loadingImage.src = Loading;

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

// const notInstalled = computed(
//     () =>
//         !(
//             window.navigator.standalone === true ||
//             window.matchMedia('(display-mode: standalone)').matches
//         )
// );

// const usingiOS = computed(() => {
//     return (
//         [
//             'iPad Simulator',
//             'iPhone Simulator',
//             'iPod Simulator',
//             'iPad',
//             'iPhone',
//             'iPod',
//         ].includes(navigator.platform) ||
//         // iPad on iOS 13 detection
//         (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
//     );
// });

onMounted(() => {
    // Redirect to /deprecated if not already there
    if (window.location.pathname !== '/deprecated') {
        console.log('Redirecting to /deprecated');
        window.location.pathname = '/deprecated';
    }

    // Only show install modal when not in production
    if (GLOBAL_VAR_IS_PRODUCTION) {
        // If using Chrome on Android
        // window.addEventListener('beforeinstallprompt', () => {
        //     installAppModalTrigger.value.click();
        // });
        // // If using Safari on iOS
        // if (usingiOS.value && notInstalled.value) {
        //     installAppModalTrigger.value.click();
        // }
    }
});

/* ================= MESSAGES WEBSOCKET ================= */

const messageStore = useMessageStore();
const { conversations } = storeToRefs(messageStore);

const getConversation = (captainId) => {
    return conversations.value.find((conversation) => {
        return conversation.person.id == captainId;
    });
};

async function reIndexMessages() {
    // This function will fetch all the people, and get the latest messages of each people
    const { unreads } = storeToRefs(messageStore);
    const res = await axios.get('messages/people');
    unreads.value = 0;
    if (res.data && res.data.length) {
        res.data.forEach((conversation) => {
            if (conversation.has_unread) {
                unreads.value++;
            }
        });
    }
}

// only if user is login, can't listen to websocket without a token)
if (user.value.id) {
    // Enable pusher logging - don't include this in production
    if (!GLOBAL_VAR_IS_PRODUCTION) {
        Pusher.logToConsole = true;
    }
    const GLOBAL_VAR_API_URL = inject('GLOBAL_VAR_API_URL');
    var pusher = new Pusher('a41f860b97777bcdacb3', {
        cluster: 'eu',
        channelAuthorization: {
            endpoint: GLOBAL_VAR_API_URL + 'broadcasting/refined-authorisation',
            headers: {
                Accept: 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(window.localStorage.getItem('user')).token,
            },
        },
    });

    var channel = pusher.subscribe('private-messages.' + user.value.id);
    channel.bind('new-message', (data) => {
        const conversation = getConversation(data.message.from_user_id);
        // If conversation null, it means the user is the one sending the message, so we don't push anything
        if (conversation) {
            reIndexMessages();
            conversation.has_unread = true;

            data.message.read = true;
            conversation.messages.unshift(data.message);
            emitter.emit('update-messages');
        }
    });

    channel.bind('message-read', (data) => {
        // This gets the conversation that belongs to the captain
        const conversation = conversations.value.find((conversation) => {
            return conversation.person.id == data.message.to_user_id;
        });

        // We make all the messages read
        const unreadMessages = conversation.messages.filter(
            (message) => !message.read
        );
        unreadMessages.forEach((message) => {
            message.read = true;
        });
    });
}
/* ================= END MESSAGES WEBSOCKET ================= */

/* ================= APP UPDATE =============== */
const refreshing = ref(false);
const registration = ref(null);
const updateExists = ref(false);

document.addEventListener('swUpdated', showRefreshUI, { once: true });

if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!refreshing.value) {
            refreshing.value = true;
            window.location.reload();
        }
    });
}

function showRefreshUI(e) {
    registration.value = e.detail;
    updateExists.value = true;
    updateAppModalTrigger.value.click();
}

function refreshApp() {
    if (!registration.value || !registration.value.waiting) {
        return;
    }
    registration.value.waiting.postMessage('skipWaiting');
}
/* ================= END APP UPDATE ================ */

// test push update
</script>
